<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <strong>
          </strong> RAMS
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-spacer></v-spacer>
          <v-text-field
              v-model="search"
              label="Search"
              single-line
              hide-details

          ></v-text-field>
        </v-card-title>
        <v-btn
            color="blue-grey"
            class="ma-2 white--text"
            @click="addRams"
        >
          Upload new RAMS document
          <v-icon
              right
              dark
          >
            mdi-file-upload
          </v-icon>
        </v-btn>
<v-col></v-col>
        <v-data-table
            :headers="headers"
            :items="rams"
            item-key="id"
            class="elevation-1 table-one"
            multi-sort
            :search="search"
            v-model="selectedRows"
            show-select
        >
          <template v-slot:item.name="{item}">
            <div class="d-flex align-center">
              <p class="ma-0 font-weight-medium">
                {{ item.name }}
              </p>
            </div>
          </template>
          <template v-slot:item.action="{item}">
            <div class="d-flex">

                <v-btn
                    color="green"
                    dark
                    outlined
                    @click.stop="viewRams(item.id)">
                  View
                </v-btn>
                <v-btn
                    color="red"
                    dark
                    outlined
                    @click="delRecord(item.id)">
                  Delete
                </v-btn>
            </div>
          </template>
        </v-data-table>
      </base-card>
    </v-col>
    <AddRamsDialog ref="addRamsDialog" @setRams="addRams" />
    <ConfirmDialog ref="confirmDelete" />
  </v-row>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import { saveAs } from 'file-saver';
import AddRamsDialog from "@/components/shared/AddRamsDialog.vue";
import toastr from "toastr";
import { ulid } from 'ulid';


export default {
  name: "Rams",
  components: {
    AddRamsDialog,
    ConfirmDialog,
  },
  data() {
    return {
      search: '',
      items: [],
      selectedRows: [],
      headers: [
        {
          text: 'Job Number',
          sortable: true,
          value: 'jobNo',
          align: 'left',
        },
        {
          text: 'Title',
          sortable: true,
          value: 'title',
          align: 'left',
        },
        {
          text: 'Lead Engineer',
          sortable: true,
          value: 'leadEngineerName',
          align: 'left',
        },
        {
          text: 'Client Manager',
          sortable: true,
          value: 'clientManagerName',
          align: 'middle',
        },
        {
          text: 'Status',
          sortable: true,
          value: 'status',
          align: 'middle',
        },
        {
          text: 'Actions',
          value: 'action',
        }
      ],
    }
  },
  computed: {
    ...mapState('rams', {loadingRams : 'isFindPending'}),
    ...mapGetters('rams', {findRamsInStore : 'find'}),

    rams() {
      return this.findRamsInStore({archived: { $ne: 1 }}).data || [];
    },
    selectedRamsIds() {
      return this.selectedRows.map(a => a.id);
    }
  },
  methods: {
    ...mapActions('rams', { findRams: 'find' }),
    deleteItem(id) {
      this.$store.dispatch('rams/remove', id)
    },
    async delRecord(id) {
      if (
          await this.$refs.confirmDelete.open(
              "Confirm",
              "Are you sure you want to delete this record?"
          )
      ) {
        this.deleteItem(id);
      }
    },
    async addRams(rams) {
      console.log(rams);
      if (
          await this.$refs.addRamsDialog.open(
              "Add Rams",
              "Please complete the form below to add a new RAMS document."
          )
      ) {
        rams.ramsUlid = ulid();
        rams.status = "Pending"
        this.$store.dispatch('rams/create', rams).then(async (result) => {
          toastr.success('Successfully created rams');
          console.log(result);

          // Check if there's a file to upload
          if (rams.file) {
            try {
              // Prepare form data for file upload
              const formData = new FormData();
              formData.append('document', rams.file);

              // Perform the file upload
              const response = await fetch(`${process.env.VUE_APP_FEATHERS_WS_URL}rams/${result.id}/upload`, {
                method: 'POST',
                body: formData
              });

              if (!response.ok) {
                throw new Error('File upload failed');
              }

              const data = await response.json();
              toastr.success('Successfully uploaded file');
              console.log(data);
            } catch (uploadError) {
              console.error('File upload error:', uploadError);
              toastr.error('Error uploading file');
            }
          }
        }).catch((error) => {
          console.log(error);
          toastr.error(error);
        });
      }
    },
    viewRams(id) {
      this.$router.push('/app/ramsview/'+id)
    },
  },
  mounted() {
    this.findRams();
    if (localStorage.ramsIndexSearch) {
      this.search = localStorage.ramsIndexSearch;
    }
  },
  watch: {
    search(newSearchTerm) {
      localStorage.ramsIndexSearch = newSearchTerm;
    }
  },
}
</script>

<style scoped>

</style>
